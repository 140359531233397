import vueFormGenerator from 'vue-form-generator';
import validators from './region.validators';

export const model = {
	RegionId: 0,
	RegionNameSk: null,
	RegionNameLokalSk: null,
	RegionNameCz: null,
	RegionNameLokalCz: null,
	RegionNamePl: null,
	RegionNameLokalPl: null,
	RegionNameDe: null,
	RegionNameLokalDe: null,
	RegionNameEn: null,
	RegionNameLokalEn: null,
	RegionNameHu: null,
	RegionNameLokalHu: null,
	CitiesCount: null,
	CountryId: null,
	RegionUrlName: null,
	RegionGoogleMapsGpsLat: null,
	RegionGoogleMapsGpsLng: null,
	RegionGoogleMapsZoom: null,
	RegionOrder: null,
	RegionBoundaries: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameSk',
				validator: validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameLokalSk',
				validator: validators.required,
				i18n: {
					label: 'localsk2557',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameCz',
				validator: validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameLokalCz',
				validator: validators.required,
				i18n: {
					label: 'localcz2559',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNamePl',
				validator: validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameLokalPl',
				validator: validators.required,
				i18n: {
					label: 'localcz2560',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameEn',
				validator: validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameLokalEn',
				validator: validators.required,
				i18n: {
					label: 'localen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameDe',
				validator: validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameLokalDe',
				validator: validators.required,
				i18n: {
					label: 'localde2563',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameHu',
				validator: validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionNameLokalHu',
				validator: validators.required,
				i18n: {
					label: 'localhu2567',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'RegionUrlName',
				required: true,
				validator: validators.regionUrlName,
				i18n: {
					label: 'urlname',
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'RegionOrder',
				i18n: {
					label: 'positiononhomep',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'RegionGoogleMapsGpsLat',
				required: true,
				// validator: vueFormGenerator.validators.double,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'latitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'RegionGoogleMapsGpsLng',
				required: true,
				// validator: vueFormGenerator.validators.double,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'longitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'RegionGoogleMapsZoom',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'zoomonmap',
				},
			},
		],
	},
];
