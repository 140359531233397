import http from '../../services/helpers/http';
import enums from '../../services/helpers/enums';
import i18n from '../../services/lang';

const validators = {
	async regionUrlName() {
		if (!this.model.RegionUrlName || this.model.RegionUrlName.isNullOrWhiteSpace()) {
			return [i18n.t('amistake!')];
		}

		if (
			!this.model.RegionUrlName ||
			this.model.RegionUrlName.isNullOrWhiteSpace() ||
			this.model.RegionUrlName.indexOf(' ') >= 0
		) {
			return [i18n.t('amistake!')];
		}

		if (!this.model.RegionUrlName || this.model.RegionUrlName.withoutDiacritics() != this.model.RegionUrlName) {
			return [i18n.t('amistake!')];
		}

		let resp = await http.get('Region/ExistsUrl', {
			regionId: this.model.RegionId,
			url: this.model.RegionUrlName,
		});

		if (resp.data === true) {
			return [i18n.t('urlnamemustbeor')];
		}

		return [];
	},
	required() {
		switch (this.schema.model) {
			case 'RegionNameSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.RegionNameSk || this.model.RegionNameSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'RegionNameLokalSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.RegionNameSk || this.model.RegionNameLokalSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'RegionNameCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.RegionNameCz || this.model.RegionNameCz.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'RegionNameLokalCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.RegionNameCz || this.model.RegionNameLokalCz.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'RegionNamePl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.RegionNamePl || this.model.RegionNamePl.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'RegionNameLokalPl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.RegionNamePl || this.model.RegionNameLokalPl.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
		}
	},
};

export default validators;
