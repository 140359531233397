<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manualforregion3326"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailoftheregi') }}
			<b-link target="_blank" :href="`${model.DomainUrl + model.RegionUrlName}`">
				{{ model.CountryId === 1 ? model.RegionNameSk : model.RegionNameCz }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.RegionId }}</label>
		</template>

		<template #beforeFormActions>
			<google-map
				:latitude="+model.RegionGoogleMapsGpsLat"
				:longitude="+model.RegionGoogleMapsGpsLng"
				:boundaries="model.RegionBoundaries"
				:zoom="+model.RegionGoogleMapsZoom"
				:onChanged="coordinatesChanged"
				height="700"
				:boundariesEnabled="true"
			></google-map>
		</template>

		<template #afterForm>
			<city-sub-table
				v-if="model.DomainUrl"
				tableName="citiesInRegion"
				:locationId="+$route.params.RegionId"
				locationQueryName="RegionId"
				:domainUrl="model.DomainUrl"
				:title="$t('citiesintheregi')"
			></city-sub-table>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '@/services/service/dictionary.service';
import { model, fields } from './region.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Region',
			routeParam: 'RegionId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		async loadResources() {
			const CountryValues = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: { values: CountryValues },
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.RegionId, this.$modal, this.deleteModel);
		},

		coordinatesChanged(lat, lng, zoom, polygonPaths) {
			const modelUpdate = {};

			if (lat) {
				modelUpdate.RegionGoogleMapsGpsLat = +lat;
				modelUpdate.RegionGoogleMapsGpsLng = +lng;
			}
			if (zoom) {
				modelUpdate.RegionGoogleMapsZoom = +zoom;
			}
			if (polygonPaths) {
				modelUpdate.RegionBoundaries = JSON.stringify(polygonPaths);
			}

			this.updateFormModel(modelUpdate);
			this.$refs[this.detailPageRefName].validate();
		},
	},
};
</script>