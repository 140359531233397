var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          tooltipMsg: "manualforregion3326",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openDeleteModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n\t\t" + _vm._s(_vm.$t("detailoftheregi")) + "\n\t\t"
                  ),
                  _c(
                    "b-link",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          "" + (_vm.model.DomainUrl + _vm.model.RegionUrlName),
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(
                            _vm.model.CountryId === 1
                              ? _vm.model.RegionNameSk
                              : _vm.model.RegionNameCz
                          ) +
                          "\n\t\t"
                      ),
                    ]
                  ),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("id")) +
                        " " +
                        _vm._s(_vm.model.RegionId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "beforeFormActions",
              fn: function () {
                return [
                  _c("google-map", {
                    attrs: {
                      latitude: +_vm.model.RegionGoogleMapsGpsLat,
                      longitude: +_vm.model.RegionGoogleMapsGpsLng,
                      boundaries: _vm.model.RegionBoundaries,
                      zoom: +_vm.model.RegionGoogleMapsZoom,
                      onChanged: _vm.coordinatesChanged,
                      height: "700",
                      boundariesEnabled: true,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "afterForm",
              fn: function () {
                return [
                  _vm.model.DomainUrl
                    ? _c("city-sub-table", {
                        attrs: {
                          tableName: "citiesInRegion",
                          locationId: +_vm.$route.params.RegionId,
                          locationQueryName: "RegionId",
                          domainUrl: _vm.model.DomainUrl,
                          title: _vm.$t("citiesintheregi"),
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1761083320
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }